body {
  margin: 0;
  box-sizing: border-box;
  background-color: #f8f8fb;
}

.App {
  text-align: center;
  background-color: #192d42;
  width: 100%;
  height: 100vh;
}

.link {
  text-decoration: none;
  color: inherit;
}
