.navbar-top-left {
  padding: 4px 0px;
}

.navbar-top-left li {
  padding: 4px 10px;
  font-size: 14px;
}

.navbar-top-left ul {
  display: flex;
  list-style-type: none;
  padding-inline-start: 0px;
}

.navbar-top-link {
  text-decoration: none;
  color: #1f2f3f;
}

.navbar-top-link:hover {
  text-decoration: underline;
}

.navbar-top-basket {
  text-decoration: none;
  color: #1f2f3f;
  line-height: -100px;
}

.navbar-bottom-right ul {
  display: flex;
  list-style-type: none;
  padding-inline-start: 0px;
}

.navbar-bottom-right li {
  /* line-height: 44px; */
}

.navbar-bottom-basket {
  /* line-height: 70px; */
  /* color: #1f2f3f; */
  /* position: relative; */
}

.navbar-basket-nb {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #de2454;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 10px;
}

.navbar-bottom-search {
  position: relative;
  margin: 0 16px;
}

.navsearch {
  padding: 6px 12px;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 220px;
}

.navbar-search-button {
  position: absolute;
  left: 3px;
  top: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.navbar-mobile-menu {
  display: none;
}

@media (max-width: 1024px) {
  .navbar-top-left {
    display: none;
    color: #1f2f3f;
  }

  .navbar-bottom-right ul {
    transition: all 0.3s ease-in-out;
    flex-flow: column nowrap;
    list-style-type: none;
    padding-inline-start: 0px;
    background-color: #1f2f3f;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    justify-content: center;
    align-items: center;
  }

  .navbar-burger-menu {
    margin-top: 11px;
  }

  .navbar-hide {
    transform: translateX(120%);
    transition: all 0.3s ease-in-out;
  }

  .navbar-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 101;
    color: #fff;
  }

  .link-white {
    color: #fff;
  }

  .navbar-mobile-menu {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
