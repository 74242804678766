/* .cart-item-container {
  padding: 20px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
} */

/* .cart-item-container:hover {
  background-color: #03474807;
} */

/* .cart-item-img {
  max-height: 180px;
} */

.cart-item-name {
  text-decoration: none;
  color: #034748;
}

.cart-item-quantity {
  padding: 10px;
  width: 60px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.cart-item-quantity:focus {
  outline: none;
}

.cart-price-container {
  display: flex;
  flex-direction: column;
}

.cart-delete-item {
  background-color: transparent;
  color: #de2454;
  border: none;
  cursor: pointer;
}
