.cart-container {
  min-height: 50vh;
  margin: 60px 80px;
}

.cart-top {
  padding: 20px 0;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  /* padding: 10px 0; */
}

.cart-checkout {
  display: flex;
  justify-content: flex-end;
}

.cart-checkout-btn {
  border: 1px solid #034748;
  padding: 10px 12px;
  color: #fff;
  background-color: #034748;
  cursor: pointer;
  margin-right: 10px;
}

.cart-checkout-btn:hover {
  background-color: transparent;
  color: #034748;
}

.cart-total-price {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.cart-bottom {
  padding: 40px 0;
  display: flex;
  justify-content: flex-end;
}

.cart-bottom-btn {
  background-color: transparent;
  color: #de2454;
  border: none;
  cursor: pointer;
  border: 1px solid #de2454;
  padding: 10px 12px;
}

.cart-bottom-btn:hover {
  background-color: #de2454;
  color: #fff;
}
