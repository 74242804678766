.cat-container {
  margin-top: 20px;
}
.cat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}

.cat-header h2 {
  color: #1f2f3f;
  font-size: 22px;
  font-weight: 600;
}

.cat-header-link {
  color: #fff;
  text-decoration: none;
  background-color: #1f2f3f;
  padding: 8px 14px;
}

.cat-header-link:hover {
  background-color: #31475c;
}

.cat-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* margin: 40px 0; */
}

.cat-container hr {
  margin-top: 30px;
  border: none;
  border-bottom: 1px solid #c8c8c8;
}
