.productstable-table {
  width: 100%;
  border-collapse: collapse;
}

.productstable-table th {
  background-color: #192d42;
  color: white;
  border: 1px solid #ddd;
  padding: 12px;
  font-weight: 600;
}

.productstable-table tr,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.productstable-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.productstable-table tr:hover {
  background-color: #ddd;
}

.table-link {
  color: #000;
}

.table-btn-edit {
  padding: 6px 10px;
  margin: 0px 4px;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.table-btn-delete {
  padding: 6px 10px;
  margin: 0px 4px;
  background-color: #f44336;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
