.orders-status-default {
  color: red;
}

.orders-status-processing {
  color: rgb(0, 0, 255);
}

.orders-status-shipping {
  color: rgb(255, 0, 255);
}

.orders-status-delilvered {
  color: green;
}

.orders-status-cancelled {
  color: rgb(0, 0, 0);
}
