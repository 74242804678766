.login-container {
  min-height: 100vh;
  background-color: #192d42;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 340px;
  height: 400px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-card img {
  width: 120px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
