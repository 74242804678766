.btn-component {
  background-color: #192d42;
  color: #fff;
  cursor: pointer;
  transition-duration: 0.3s;
  border: none;
  padding: 8px 12px;
  font-size: 1.1rem;
}

.btn-component:hover {
  background-color: #263d55;
}
