.footer-column {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-ul {
  list-style-type: none;
}

.footer-ul li {
  margin: 20px 0;
}

.footer-link {
  text-decoration: none;
  color: #fff;
}

.footer-link:hover {
  text-decoration: underline;
}
/* #d3d3d3 */

.footer-contact-item {
  display: flex;
  color: #fff;
  margin: 10px 0;
}

.footer-contact-item p {
  padding: 6px 14px;
}

.footer-sm {
  margin-top: 20px;
}

.footer-sm a {
  margin: 0 6px;
}

@media (max-width: 1024px) {
  .footer {
    min-height: 800px;
    flex-direction: column;
    padding: 50px 0;
  }
}
