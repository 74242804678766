.checkout-confirmation-container {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.checkout-confirmation-container h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.checkout-confirmation-container p {
  font-size: 18px;
  margin-bottom: 10px;
}

.checkout-confirmation-container h4 {
  margin: 20px 0;
  font-size: 20px;
}

/* Buttons */
.checkout-confirmation-btns {
  margin-top: 30px;
}

.checkout-confirmation-back {
  border: 1px solid #de2454;
  padding: 10px 12px;
  color: #de2454;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}
.checkout-confirmation-back:hover {
  background-color: #de2454;
  color: #fff;
}

.checkout-confirmation-submit {
  border: 1px solid #034748;
  padding: 10px 12px;
  color: #034748;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}

.checkout-confirmation-submit:hover {
  background-color: #034748;
  color: #fff;
}
