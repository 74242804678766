.product-container {
  width: 100%;
}

.product-details {
  display: flex;
  justify-content: center;
  padding: 40px 80px;
  width: 100%;
  color: #1f2f3f;
}

.product-price {
  margin-top: 10px;
}

.product-price-before {
  color: #999;
  position: relative;
  font-size: 16px;
}

.product-price-before::after {
  content: "";
  position: absolute;
  left: -2px;
  right: -2px;
  top: 50%;
  border-bottom: 1px solid;
}

.product-price {
  color: #1f2f3f;
  font-size: 22px;
}

.product-info-btn {
  margin: 20px 0;
  border-radius: 20px;
  padding: 10px 12px;
  border: 1px solid #034748;
  color: #fff;
  cursor: pointer;
}

.product-info-btn:hover {
  color: #034748;
  background-color: #fff;
  border: 1px solid #034748;
}

.product-details-h4 {
  margin-bottom: 20px;
  font-size: 20px;
}

.product-details-p {
  margin-bottom: 14px;
  font-size: 18px;
}
