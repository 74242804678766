.pagination-container {
  padding: 40px 10px;
  width: 100%;
}

.pagination-btn:hover {
  background-color: #31475c;
}

.pagination-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
