.category-card {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 4px rgb(202, 202, 202);
  border-radius: 4px;
  margin: 10px 40px;
}

.categories-submit {
  font-size: 1.2rem;
  padding: 6px 10px;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.category-element {
  display: flex;
  margin: 4px 0;
}
.category-element p {
  padding: 4px 10px;
}

.category-btn-delete {
  padding: 4px 10px;
  margin-right: 4px;
  background-color: #f44336;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
