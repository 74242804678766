.select-component {
  padding: 10px 10px;
  color: #192d42;
  border-radius: 4px;
  border: solid 1px #192d42;
  font-size: 1.2rem;
  margin: 10px 0;
}

.select-component:focus {
  outline: none;
}
