@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Tajawal", sans-serif;
  box-sizing: border-box;
  margin: 0;
  font-family: "Almarai";
}

.container {
  margin-right: 250px;
  color: #495057;
}

.ph-10 {
  padding: 0 10px;
}

.pb-40 {
  padding-bottom: 20px;
}

.static-container {
  min-height: 60vh;
}

@layer components {
  .cp {
    @apply mr-[250px];
  }
  .btn-link {
    @apply no-underline py-3 px-4 bg-[#192d42] rounded-md text-white;
  }
  .btn-link:hover {
    @apply bg-[#192d60];
  }
}
