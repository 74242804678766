.book-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  /* width: 100%; */
}

.book-card-img-container {
  height: 280px;
  width: 200px;
}

.book-card-add-to-cart-btn {
  position: absolute;
  top: 8px;
  right: 18px;
  border: none;
  background-color: #1f2f3f;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-card-container img {
  width: 100%;
  max-height: 280px;
  object-fit: cover;
}

.book-card-container img:hover {
  opacity: 0.95;
}

.book-card-title {
  text-decoration: none;
  color: #1f2f3f;
  margin-top: 8px;
  margin-bottom: 10px;
}

.price-before {
  color: #999;
  position: relative;
  font-size: 14px;
}

.price-before::after {
  content: "";
  position: absolute;
  left: -2px;
  right: -2px;
  top: 50%;
  border-bottom: 1px solid;
}

.price {
  color: #1f2f3f;
}
