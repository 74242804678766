.checkout-form {
  padding: 20px;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout-form h3 {
  margin-bottom: 30px;
}

.checkout-row {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  font-size: 14px;
}

.checkout-row-side {
  margin: 0 20px;
}

.checkout-row input {
  display: block;
  padding: 8px 12px;
  width: 250px;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 6px;
}

.checkout-row select {
  display: block;
  padding: 8px 12px;
  width: 250px;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 6px;
}

.checkout-address {
  display: flex;
  margin: 20px 0;
}

.checkout-address label {
  margin: 0 20px;
  font-size: 14px;
}

.checkout-address textarea {
  padding: 8px 12px;
  font-size: 18px;
  width: 100%;
  margin: 0 20px;
  display: block;
  resize: none;
}

.checkout-btn button {
  border: 1px solid #034748;
  padding: 10px 12px;
  color: #034748;
  background-color: transparent;
  font-size: 16px;
}

.checkout-btn button:hover {
  background-color: #034748;
  color: #fff;
}

.checkout-details {
  text-align: center;
  flex: 1;
}

.checkout-details-container {
  padding: 20px 30px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.checkout-summary-row {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

.code-promo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.code-promo input {
  display: block;
  padding: 8px 12px;
  width: 250px;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 6px;
}

.code-promo label {
  font-size: 14px;
}

.code-promo .error-Message {
  color: red;
  font-size: 14px;
  margin-top: 6px;
}

.code-promo .success-Message {
  color: green;
  font-size: 14px;
  margin-top: 6px;
}
