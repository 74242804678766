.order-content {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 4px rgb(202, 202, 202);
  border-radius: 4px;
}

.order-content p {
  margin: 10px 0;
}
