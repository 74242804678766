.signup-container {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-form {
  margin: 40px 0;
  display: flex;
  flex-direction: column;

  /* align-items: center; */
}

.signup-container h3 {
  margin-top: 60px;
}

.signup-row {
  display: flex;
  margin: 20px 0;
  font-size: 14px;
}

.signup-row-side {
  margin: 0 20px;
}

.signup-row input {
  display: block;
  padding: 8px 12px;
  width: 250px;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 6px;
}

.signup-address {
  display: flex;
  margin: 20px 0;
}

.signup-address label {
  margin: 0 20px;
  font-size: 14px;
}

.signup-address textarea {
  padding: 8px 12px;
  font-size: 18px;
  width: 100%;
  margin: 0 20px;
  display: block;
  resize: none;
}

.signup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-form button {
  width: 200px;
  color: #fff;
  background-color: #192d42;
  cursor: pointer;
  transition-duration: 0.3s;
  border: none;
  padding: 12px 12px;
}

.signup-form button:hover {
  background-color: #395574;
}
