.sidebar {
  background-color: #192d42;
  width: 250px;
  height: 100vh;
  color: #fff;
  position: fixed;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
}

.sidebar-header img {
  width: 80px;
}

.sidebar-menu h5 {
  font-size: 14px;
  padding: 12px 20px;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-ul {
  list-style: none;
  padding-right: 0;
}

.sidebar-links {
  display: block;
  width: 100%;
}

.sidebar-links a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  padding: 8px 32px;
  display: block;
  transition: all 0.4s;
  /* transition-duration: 0.3s; */
}

.sidebar-links a:hover {
  color: #fff;
}

.sidebar-links a.navbar-link-active {
  color: #fff;
}
