.static-content-container {
  box-shadow: 0px 0px 4px rgb(202, 202, 202);
}

.static-content-container h1 {
  margin-bottom: 30px;
}

.static-content-container p {
  margin-bottom: 20px;
  line-height: 1.6rem;
}

.static-content-container a {
  text-decoration: none;
  color: #192d42;
}

.static-content-container a:hover {
  text-decoration: none;
  color: #5281b3;
}
